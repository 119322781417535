<template>
  <div class="chat-component">
    <v-row no-gutters>
    <v-col
        cols="12"
        sm="6"
        md="8"
      >
    <beautiful-chat
      :participants="participants"
      :titleImageUrl="titleImageUrl"
      :onMessageWasSent="onMessageWasSent"
      :messageList="messageList"
      :new-messages-count="newMessagesCount"
      :isOpen="isChatOpen"
      :close="closeChat"
      :showHeader="false"
      :icons="icons"
      :open="openChat"
      :showEmoji="false"
      :showFile="false"
      :show-launcher="false"
      :showEdition="true"
      :showDeletion="true"
      :showTypingIndicator="showTypingIndicator"
      :showLauncher="false"
      :showCloseButton="true"
      :colors="colors"
      :alwaysScrollToBottom="alwaysScrollToBottom"
      :messageStyling="messageStyling"
      @onType="handleOnType"
      @edit="editMessage">
        <template v-slot:user-avatar="{ message }">
          <v-img v-if="message.isAdmin" src="https://avatars3.githubusercontent.com/u/37018832?s=200&v=4" :max-height="25" :max-width="25" contain>
          </v-img>
          <div v-else>
            <div v-if="message.type === 'text' && message.author && message.author != 'me'" class="message">
              {{message.author.toUpperCase()[0] + message.author.toUpperCase()[1]}}
            </div>
          </div>
        </template>

        <template v-slot:text-message-body="{ message }">
          <div :class="[ message.isAdmin && 'admin-message']">
            {{ message.data.text }}
          </div>
        </template>
      </beautiful-chat>
      </v-col>
    </v-row>
  </div>
</template>

<script>

import { client } from '@/services/chat';

import request from '@/plugins/request';

import CloseIcon from '@/assets/close-icon.png'
import OpenIcon from '@/assets/logo-no-bg.svg'
import FileIcon from '@/assets/file.svg'
import CloseIconSvg from '@/assets/close.svg'

let mainChannel;

export default {
  name: 'beauty-chat',
  props: {
    selfId: { type: Number, required: true },
    selfUserId: { type: String, required: true },
    selfName: { type: String, required: true },
    channelName: { type: String, required: true },
    users: { type: Array },
    messages: { type: Array },
    adminPayload: { type: Object },
    microMuted: { type: Boolean, required: true },
    microDisabled: { type: Boolean, default: false},
    isAdmin: { type: Boolean, default: false }
  },
  data() {
    return {
      icons:{
        open:{
          img: OpenIcon,
          name: 'default',
        },
        close:{
          img: CloseIcon,
          name: 'default',
        },
        file:{
          img: FileIcon,
          name: 'default',
        },
        closeSvg:{
          img: CloseIconSvg,
          name: 'default',
        },
      },
      participants: [], // the list of all the participant of the conversation. `name` is the user name, `id` is used to establish the author of a message, `imageUrl` is supposed to be the user avatar.
      titleImageUrl: 'https://a.slack-edge.com/66f9/img/avatars-teams/ava_0001-34.png',
      messageList: [], // the list of the messages to show, can be paginated and adjusted dynamically
      newMessagesCount: 0,
      isChatOpen: true, // to determine whether the chat window should be open or closed
      showTypingIndicator: '', // when set to a value matching the participant.id it shows the typing indicator for the specific user
      colors: {
        header: {
          bg: 'rgba(0, 0, 0, 0.6)',
          text: '#ffffff'
        },
        launcher: {
          bg: '#4e8cff'
        },
        messageList: {
          bg: 'rgba(0, 0, 0, 0.5)'
        },
        sentMessage: {
          bg: 'rgba(0, 0, 0, 0.3)',
          text: '#ffffff'
        },
        receivedMessage: {
          bg: 'rgba(240, 240, 240, 0.3)',
          text: '#ffffff'
        },
        userInput: {
          bg: 'rgba(0, 0, 0, 0.6)',
          text: '#f4f7f9'
        }
      }, // specifies the color scheme for the component
      alwaysScrollToBottom: false, // when set to true always scrolls the chat to the bottom when new events are in (new message, user starts typing...)
      messageStyling: true // enables *bold* /emph/ _underline_ and such (more info at github.com/mattezza/msgdown)
    }
  },
  mounted(){
    this.participants = this.users;
    this.messageList = this.messages;
    this.setup();
  },
  beforeDestroyed() {
      mainChannel.leave();
      mainChannel = null;
  },
  methods: {
    async setup() {
      mainChannel = client.createChannel(this.channelName);

      await mainChannel.join();

      mainChannel.on('ChannelMessage', (message, member) => {
        let self = this

        if (message && message.text.includes('userId')) {
          this.updateMicroState(JSON.parse(message.text))
        } else {
          const author = self.participants.find( part => part.id == member);
          const payload = JSON.parse(message.text);

          console.log(message.text, payload);

          if(!payload.isAdmin){
            self.newMessagesCount = self.isChatOpen ? self.newMessagesCount : self.newMessagesCount + 1
            self.messageList = [
              ...self.messageList,
              {
                type: payload.isAdmin ? 'system' : 'text',
                author: author.name,
                isAdmin: payload.isAdmin,
                imageUrl: author.imageUrl,
                data: { text: `${payload.text}` }
              }
            ]
          }
        }
      });
    },
    sendMessage (text) {
      if (text.length > 0) {
        this.newMessagesCount = this.newMessagesCount + (this.isChatOpen ? 0 : 1);

        this.onMessageWasSent({ author: this.selfName, type: 'text', data: { text } })
      }
    },
    onMessageWasSent (message) {
      console.log(message);
      if (message.data.text.trim().length === 0) {
        console.log('empty message');
        return;
      }

      // called when the user sends a message
      this.messageList = [ ...this.messageList, message ]

      if (this.isAdmin) {
        console.log('sending announcement')

        request({
          method: 'POST',
          url: `/concert/api/v1/Channel/AnnouncementMessage?text=${message.data.text}&showId=9987`,
          body: [
            //this.$route.params.id
          ]
        });

        mainChannel.sendMessage({
          messageType: "TEXT",
          text: JSON.stringify({ text: message.data.text, isAdmin: true })
        });

        return;
      }

      mainChannel.sendMessage({
        messageType: "TEXT",
        text: JSON.stringify({ text: message.data.text })
      });

      this.$emit('message-sent', {
        ...this.adminPayload,
        content: message.data.text,
        userId: this.selfUserId,
        disabled: this.microDisabled
      });

    },
    updateMicroState(data) {
      this.$emit("update-micro-state", data);
    },
    openChat () {
      // called when the user clicks on the fab button to open the chat
      this.isChatOpen = true;
      this.$emit('is-chat-open', this.isChatOpen);
      this.newMessagesCount = 0
    },
    closeChat () {
      // called when the user clicks on the botton to close the chat
      this.isChatOpen = false;
      this.$emit('is-chat-open', this.isChatOpen);
    },
    handleScrollToTop () {
      // called when the user scrolls message list to top
      // leverage pagination for loading another page of messages
    },
    handleOnType () {
      console.log('typing')
      //this.showTypingIndicator = 'typing'
    },
    editMessage(message){
      const m = this.messageList.find(m=>m.id === message.id);
      m.isEdited = true;
      m.data.text = message.data.text;
    }
  },
  watch: {
    users(value) {
      this.participants = value;
    },
    messages(value) {
      this.newMessagesCount = this.isChatOpen ? this.newMessagesCount : this.newMessagesCount + 1
      this.messageList = [
        ...this.messageList,
        ...value
      ];
    },
    microMuted(value){
      let message = {
        userId: this.selfId,
        muted: value
      };

      mainChannel.sendMessage({
        messageType: "TEXT",
        text: JSON.stringify(message)
      });
    }
  }
}
</script>

<style scoped>
>>> .admin-message {
  text-align: center;

  background-color: rgb(219, 32, 32);
}

>>> .message {
  border-radius: 50%;

  font-size: 15px;
  font-weight: bold;

  text-align: center;

  color: pink;
  background: tomato;

  line-height: 25px;
  width: 25px !important;
  height: 25px !important;

  min-width: 30px;
  min-height: 30px;

  margin: 5px;
}
>>> .sc-launcher
{
  position: absolute;
  right:3%;
  bottom: 12%;
}

>>> .sc-launcher .sc-open-icon,
>>> .sc-launcher .sc-closed-icon
{
  position: absolute;
  right: 1%;
  bottom: 2%;
}
>>> .sc-message-list {
  height: calc(65vh - 5vh);
  padding-left: 15px;
  padding-right: 15px;
  border-bottom: 1px solid rgb(255 255 255 / 39%);
  border-radius: 12px 12px 0 0;
}

>>> .sc-message-list  ::-webkit-scrollbar {
  background: rgba(216, 216, 216, 0.40879);
  border-radius: 1.5px;
  width: 3px;
}

>>> .sc-message-list ::-webkit-scrollbar-thumb {
   background: #D8D8D8;
 }

>>> .sc-message {
  width: inherit;
}

>>> .sc-user-input--text {
  max-width: 80%;
}

>>> .sc-message--content.sent .sc-message--text {
  line-height: 16px;
  order: revert;
  text-align: left;
  letter-spacing: -0.24px;
  width: 100%;
  max-width: inherit;
  margin-right: 0;
  border-radius: 16px 0 16px 16px;
}

>>> .sc-message--content.received .sc-message--text {
  background: rgba(240, 240, 240, 0.3);
  line-height: 16px;
  letter-spacing: -0.24px;
  width: 100%;
  margin-right: 0;
  border-radius: 0px 16px 16px 16px;
}

>>> .sc-chat-window {
  width: 214px !important;
  min-width: 214px;
  position: fixed;
  border: 1px solid #FFFFFF;
  box-sizing: border-box;
  border-radius: 12px;
  right:3%;
  top: 15%;
  height: 30vh;
}
</style>
<template>
  <div style="width: 100%" class="pods-control">
    <div
      v-if="(!micStatus || !micAccess) && showMicTutorial"
      class="tutorial-modal"
    >
      <v-img src="@/assets/allow-mic-tutorial.png"></v-img>
    </div>
    <v-row class="controls" justify="center" align="center" dense no-gutters>
      <v-fade-transition mode="out-in">
        <v-col class="logo-name">
          <div class="vitaverse">
            <IconLogo />
          </div>
          <div class="show-name">
            {{ showName }}
          </div>
        </v-col>
      </v-fade-transition>
      <v-col class="d-flex sound-setting-center" style="z-index: 110">
        <div v-if="showTutorial" class="btn-sound-tutorial">SOUND SETTINGS</div>
        <div class="volume-middle-block">
          <div class="volume" ref="volume">
            <div class="slider-row">
              <div class="slider-wrap">
                <img
                  v-show="microVolume === 0"
                  src="@/assets/menu/volume-off-white.svg"
                />
                <img
                  v-show="microVolume > 0"
                  src="@/assets/menu/volume-on-white.svg"
                />
                <span>MIC</span>
              </div>

              <v-slider
                thumb-color="#fff"
                class="slider"
                step="5"
                ticks="always"
                tick-size="0"
                :value="microVolume"
                color="red"
                hide-details
                @change="changeMicroVolume"
              ></v-slider>
            </div>

            <div class="slider-row">
              <div class="slider-wrap">
                <img
                  v-show="crowdValue === 0"
                  src="@/assets/menu/volume-off-white.svg"
                />
                <img
                  v-show="crowdValue > 0"
                  src="@/assets/menu/volume-on-white.svg"
                />
                <span>CROWD</span>
              </div>
              <v-slider
                thumb-color="#fff"
                class="slider"
                step="5"
                ticks="always"
                tick-size="0"
                :value="crowdVolume"
                color="red"
                hide-details
                @change="changeCrowdVolume"
              ></v-slider>
            </div>
            <div class="slider-row">
              <div class="slider-wrap">
                <img
                  v-show="musicVolume === 0"
                  src="@/assets/menu/volume-off-white.svg"
                />
                <img
                  v-show="musicVolume > 0"
                  src="@/assets/menu/volume-on-white.svg"
                />
                <span>MUSIC</span>
              </div>
              <v-slider
                thumb-color="#fff"
                class="slider"
                step="2"
                ticks="always"
                tick-size="0"
                :value="musicVolume"
                color="red"
                hide-details
                @change="changeVolume"
              ></v-slider>
            </div>
          </div>
          <v-tooltip top>
            <template #activator="{ attrs, on }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                ref="microphone"
                :disabled="microphoneDisabled"
                icon
                :class="['microphone', microphoneMuted && 'microphone--muted']"
                @click="$emit('toggle-mute')"
              >
                <v-badge avatar overlap offset-x="20" tile color="none">
                  <template v-if="!micStatus || !micAccess" v-slot:badge>
                    <v-avatar tile>
                      <v-img src="@/assets/warning.svg"></v-img>
                    </v-avatar>
                  </template>

                  <v-avatar
                    size="70"
                    :color="
                      !micStatus || !micAccess ? 'rgba(255, 0, 0, 0.2674)' : ''
                    "
                    @mouseover="showMicTutorial = true"
                    @mouseleave="showMicTutorial = false"
                  >
                    <v-icon
                      v-if="!microphoneMuted"
                      @click="mute(true)"
                      size="70"
                      >$vuetify.icons.micIcon</v-icon
                    >
                    <v-icon
                      v-if="microphoneMuted"
                      @click="mute(false)"
                      size="70"
                      >$vuetify.icons.micIconOff</v-icon
                    >
                  </v-avatar>
                </v-badge>
              </v-btn>
            </template>
            <div v-if="!micStatus || !micAccess" class="hint">
              {{ hintMessage }}
            </div>
            <div v-else class="hint">
              RIGHT NOW THE MIC IS
              <span :class="`hint--${microphoneMuted ? 'off' : 'on'}`">
                {{ microphoneMuted ? "OFF" : "ON" }}
              </span>
            </div>
          </v-tooltip>
        </div>
        <div v-if="showTutorial" class="btn-sound-tutorial">MIC ON/OFF</div>
      </v-col>

      <v-fade-transition mode="out-in">
        <v-col class="control d-flex align-end flex-column">
          <div
            @click="$emit('toggle-chat')"
            class="icon-message"
            v-if="!showTutorial"
          >
            <icon-message />
          </div>
        </v-col>
      </v-fade-transition>
    </v-row>
  </div>
</template>

<script>
import IconLogo from "@/components/icons/IconLogo.vue";
import IconMessage from "@/components/icons/IconMessage.vue";

export default {
  props: {
    fullscreen: { type: Boolean, default: false },
    microphoneMuted: { type: Boolean, default: false },
    microphoneDisabled: { type: Boolean, default: false },
    showName: { type: String, default: "Noname Show" },
    showTutorial: { type: Boolean, default: false },
    selfUid: { type: Number, default: null },
    isAddMenu: { type: Boolean, default: false },

    musicVolume: { type: Number, default: 40 },
    crowdVolume: { type: Number, default: 60 },
    microVolume: { type: Number, default: 80 },
  },
  data() {
    return {
      musicValue: 40,
      crowdValue: 60,
      microValue: 80,
      micAccess: true,
      micStatus: true,
      hintMessage: "NO ACCESS TO MIC",
      showMicTutorial: false,
    };
  },
  components: { IconLogo, IconMessage },
  mounted() {
    this.checkMediaAccess();
  },
  methods: {
    checkMediaAccess() {
      let self = this;
      navigator.permissions
        .query({ name: "microphone" })
        .then(function (permissionStatus) {
          permissionStatus.onchange = function () {
            self.styleMicButton(this.state);
          };
        });
    },
    styleMicButton(state) {
      switch (state) {
        case "denied":
          this.micAccess = false;
          this.hintMessage = "NO ACCESS TO MIC";
          break;
        case "granted":
          this.micAccess = true;
          if (!navigator.getUserMedia) {
            self.micStatus = false;
            self.hintMessage = "MIC NOT CONNECTED";
          } else {
            self.micStatus = true;
          }
          break;
        case "prompt":
          navigator.mediaDevices.getUserMedia({ video: false, audio: true });
          break;
        default:
          break;
      }
    },
    mute(value) {
      this.microphoneMuted = value;
    },
    changeCrowdVolume(value) {
      this.crowdValue = value;
      this.$emit("change-crowd-volume", value);
    },
    changeMicroVolume(value) {
      this.microVolume = value;
      this.$emit("change-micro-volume", value);
    },
    changeVolume(value) {
      this.musicVolume = value;
      this.$emit("change-volume", value);
    },
  },
  watch: {},
};
</script>

<style lang="scss" scoped>
.volume {
  width: 250px;
  margin-right: 17px;

  span {
    font-style: normal;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 23px;
  }
}

.icon-message {
  border: 2px solid;
  cursor: pointer;
  padding: 6px !important;
  margin: 0 2rem 20px;
  border-radius: 50%;
}

.slider-row {
  display: flex;
  align-items: center;
  height: 25px;
  .slider-wrap {
    text-align: left;
    min-width: 80px;
    img {
      vertical-align: middle;
      width: 16px;
      margin-right: 3px;
    }
    .volume-up {
      fill: #ccc;
    }
  }

  & > span {
    text-transform: uppercase;
    width: 50px;
    text-align: right;
    font-weight: bold;
    font-size: 14px;
  }

  ::v-deep .v-slider__track-background {
    background: rgba(0, 0, 0, 0.111634) !important;
    border-radius: 5px;
    border: 0.75px solid #ffffff;
  }

  ::v-deep .v-slider__track-fill {
    border-radius: 5px;
  }

  ::v-deep .v-slider--horizontal .v-slider__track-container {
    height: 5px;
    border-radius: 5px;
  }

  ::v-deep .v-slider__thumb {
    height: 15px;
    width: 15px;
  }

  & > .slider {
    width: 200px;
    margin: 0 0.1rem;
  }
}

.controls {
  position: relative;
  z-index: 110 !important;
}

.microphone {
  position: relative;
  cursor: pointer;
  margin: auto 0;
}

.hint {
  font-weight: 500;
  font-size: 16px;
}

.hint--on {
  color: rgb(38, 169, 38);
}
.hint--off {
  color: rgb(197, 39, 39);
}

.logo-name {
  display: grid;
  grid-template-rows: 10px 25px;
  grid-template-columns: 60px auto;
  grid-template-areas:
    "logo vitaverse"
    "logo show-name";
  place-items: center start;
  .logo {
    grid-area: logo;
  }

  .vitaverse {
    grid-area: vitaverse;
    svg {
      width: 100%;
    }
  }

  .show-name {
    grid-area: show-name;
  }
}
.controls-setting {
  z-index: 110 !important;
  position: relative;
  height: 5vh;
}

.show-name {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: -1.44px;
  color: #ffffff;
}
.sound-setting-center {
  display: flex !important;
  align-items: center;
  justify-content: center;
  // margin-left: 80px;
}

.subtitle {
  padding: 0.25rem;
}

.logo {
  border-radius: 50%;
  border: 3px solid #2b2b2b;

  width: 50px;
  height: 50px;

  text-align: center;
  line-height: 45px;
}

.microphone {
  border-radius: 50%;
  width: 70px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba($color: #111, $alpha: 0.5);
  transition: background-color 0.3s;

  &--muted {
    background-color: rgba($color: #666, $alpha: 0.2);
  }

  &:hover &--muted {
    background-color: rgba($color: #666, $alpha: 0.3);
  }
}

.microphone:hover {
  background-color: rgba($color: #111, $alpha: 0.7);
}

.v-badge--avatar .v-badge__badge .v-avatar {
  width: 30px !important;
  height: 30px !important;
  max-width: 30px !important;
}

.control {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;
  bottom: 5rem;

  & > *:not(.v-divider) {
    padding: 0 1.5rem;
  }

  & > *:last-child {
    padding: 0 2rem 4rem;
  }
}

.control .camera-label {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: -1px;
}

.control .camera {
  display: grid;
  grid-template-rows: 30px 30px;
  grid-template-columns: auto auto;
  grid-template-areas:
    "label label"
    "mouse keyboard";

  & > .camera-label {
    grid-area: label;
  }
}
.tutorial-modal {
  justify-content: center;
  width: 400px;
  height: 0px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.volume-middle-block {
  display: flex;
  flex-direction: row;
  padding-right: 5%;
  padding-left: 5%;
  margin-bottom: 10px;
}
.btn-sound-tutorial {
  position: relative;
  width: 130px;
  text-align: center;
  color: rgba(255, 60, 17, 1);
  font-size: 15px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Oswald;
  font-style: normal;
  font-weight: 500;
  letter-spacing: -1px;
  border: solid 1px rgba(255, 255, 255, 1);
  border-radius: 12px;
  background-color: rgba(0, 0, 0, 0.543271);
  transition: all 0.7s ease-out;
}

@media screen and (min-width: 1600px) {
  .volume {
    width: 270px;
    margin-right: 27px;
    span {
      font-size: 16px;
    }
  }

  .icon-message {
    padding: 16px !important;
    margin: 0 2rem 20px;
  }

  .slider-row {
    height: 27px;
    .slider-wrap {
      text-align: left;
      min-width: 80px;
      img {
        width: 20px;
      }
    }
  }

  .sound-setting-center {
    // margin-left: 100px;
    // display: flex;
  }
  .microphone {
    width: 70px;
    height: 70px;
  }

  .volume-middle-block {
    margin-bottom: 20px;
  }
  .btn-sound-tutorial {
    width: 170px;
    font-size: 18px;
    height: 60px;
  }
}

@media screen and (min-width: 1920px) {
  .volume {
    width: 300px;
    margin-right: 27px;
    span {
      font-size: 19px;
    }
  }

  .slider-row {
    height: 35px;
    .slider-wrap {
      img {
        width: 20px;
        margin-right: 6px;
      }
    }

    ::v-deep .v-slider--horizontal .v-slider__track-container {
      height: 7px;
    }
  }

  .btn-sound-tutorial {
    width: 180px;
    font-size: 22px;
    height: 60px;
  }
   .sound-setting-center {
    // margin-left: 120px;
    display: flex;
  }
}
</style>

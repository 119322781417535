<template>
  <div
    class="guest d-flex align-center"
    :class="[listView ? 'flex-row list-view' : 'flex-column']"
  >
    <div
      @click="select"
      class="guest-circle"
      :class="[selected && 'guest--selected']"
    >
      <v-btn icon> </v-btn>
    </div>

    <div class="guest-name">
      {{ name || "Guest " + uid }}
    </div>

    <div
      class="caption grey--text control-menu"
      :class="[listView && 'text-right']"
    >
      <v-icon v-show="muted" class="control-icon" color="white" size="11"
        >fa fa-volume-off
      </v-icon>
      <v-icon v-show="!muted" class="control-icon" color="white" size="11">
        fa fa-volume-up
      </v-icon>
      <v-icon v-if="!microMuted" class="control-icon" color="white" size="11">
        fa fa-microphone
      </v-icon>
      <v-icon v-if="microMuted" class="control-icon" color="white" size="11">
        fa fa-microphone-slash
      </v-icon>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    uid: { type: [Number, String], required: true },
    name: { type: String, default: null },
    muted: { type: Boolean, default: false },
    microMuted: { type: Boolean, default: true },
    selected: { type: Boolean, default: false },
    listView: { type: Boolean, default: false },
  },
  data() {
    return {
      currentState: false,
    };
  },
  methods: {
    select() {
      if (!this.selected) {
        this.$emit("selected", this.uid);
      } else {
        this.$emit("unselected", this.uid);
      }
    },
  },
  computed: {
    color() {
      return this.selected && (!this.mode || !this.canSelect)
        ? "black"
        : "grey";
    },
    canSelect() {
      return (
        !this.mode ||
        (this.mode === "unmute" && this.muted) ||
        (this.mode === "mute" && !this.muted)
      );
    },
  },
  watch: {
    muted(value, oldValue) {
      console.log(value, oldValue);
      this.currentState = value;
      // this.selected = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.guest {
  padding: 0 3px;
  transition: border-color 0.4s;
}

.list-view {
  justify-content: space-between;
  padding-bottom: 10px;
  .guest-circle {
    margin-right: 5px;
  }
}
.guest-circle {
  background: #929292;
  border-radius: 50%;
  width: 30px;
  height: 30px;
}

.guest--selected {
  font-weight: 700 !important;
  border-color: #ffffff;
}

.guest-name {
  font-size: 12px;
  font-weight: 300;
  text-align: center;
}

.control-icon {
  &:after {
    content: none;
  }

  padding: 2px;
}

@media screen and (min-width: 1420px) {
  .guest-circle {
    width: 35px;
    height: 35px;
  }
  .guest-name {
    font-size: 14px;
  }
}

@media screen and (min-width: 1600px) {
  .guest-circle {
    width: 35px;
    height: 35px;
  }
  .guest-name {
    font-size: 14px;
  }
}

@media screen and (min-width: 1920px) {
  .guest-circle {
    margin-top: 10px;
    width: 40px;
    height: 40px;
  }
  .guest-name {
    font-size: 17px;
  }
}
</style>

<template>
  <article class="pod-guests">
    <main
      class="guests"
      :class="[isExpanded && 'full', listView && 'list-guests']"
    >
      <pod-guest
        :uid="guest.uid"
        :name="guest.name"
        :muted="guest.muted"
        :listView="listView"
        :microMuted="guest.microMuted"
        :selected="guest.uid === selected"
        :key="`guest-${i}`"
        v-for="(guest, i) in users"
        @selected="select"
        @unselected="unselect"
      />
    </main>
    <footer v-if="!listView" class="flex-column">
      <template v-if="isExpanded">
        <v-btn class="btn" small block outlined @click="mute">
          {{ this.mode.toUpperCase() }}
        </v-btn>

        <v-btn class="btn" small block outlined @click="report"> REPORT </v-btn>

        <v-btn class="btn" small block outlined @click="bugReport">
          BUG REPORT
        </v-btn>

        <v-btn
          v-if="isAdmin"
          small
          block
          :disabled="!emergencyAvailable"
          outlined
          class="btn"
          @click="emergencyCamera"
        >
          EMERGENCY CAMERA
        </v-btn>
      </template>

      <template v-if="!isExpanded">
        <v-btn @click="mute" width="42" height="30" outlined x-small>
          <v-icon class="control-icon mr-2" color="white" size="15" right>
            {{ this.mode === "mute" ? "fa fa-volume-mute" : "fa fa-volume-up" }}
          </v-icon>
        </v-btn>
        <v-btn @click="report" width="42" height="30" outlined x-small>
          <img width="15px" :src="reportSrc" />
        </v-btn>
        <v-btn @click="bugReport" width="42" height="30" outlined x-small>
          <v-icon size="15">$vuetify.icons.emailIcon</v-icon>
        </v-btn>
        <v-btn
          v-if="isAdmin"
          @click="emergencyCamera"
          width="2"
          height="30"
          :disabled="!emergencyAvailable"
          outlined
          x-small
        >
          <v-icon size="15">$vuetify.icons.cameraIcon</v-icon>
        </v-btn>
      </template>
    </footer>
  </article>
</template>

<script>
import PodGuest from "./PodGuest";
import Report from "@/assets/menu/report.svg";
import { mapMutations, mapActions, mapState } from "vuex";

export default {
  components: {
    PodGuest,
  },
  props: {
    listView: { type: Boolean, default: false },
    isExpanded: { type: Boolean, default: false },
    emergencyAvailable: { type: Boolean, default: false },
    users: { type: Array, default: () => [] },
    isAdmin: { type: Boolean, default: false },
  },
  data() {
    return {
      reportSrc: Report,
      selected: null,
      mode: "mute",
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user.username,
    }),
  },
  methods: {
    select(uid) {
      this.selected = uid;
      const user = this.users.find((u) => u.uid === uid);
      this.$emit("set-selected-user", user);
      this.mode = !user.muted ? "mute" : "unmute";
    },
    unselect() {
      this.selected = null;
      this.mode = "mute";
    },
    mute() {
      if (this.selected) {
        this.addLog(`${this.user} pressed '${this.mode.toUpperCase()}'`);
        this.sendLog();
        console.log("MUTE", this.selected);
        this.$emit("mute", this.selected);
        this.selected = null;
        this.mode = "mute";
      }
    },
    report() {
      if (this.selected) {
        this.addLog(`${this.user} pressed 'REPORT'`);
        this.sendLog();
        this.$emit("report", this.selected);
        this.selected = null;
        this.mode = "mute";
      }
    },
    bugReport() {
      this.addLog(`${this.user} pressed 'BUG REPORT'`);
      this.sendLog();
      this.$zendesk.toggle();
      this.$emit("bug-report");
    },
    emergencyCamera() {
      this.addLog(`${this.user} pressed 'EMERGENCY CAMERA'`);
      this.sendLog();
      this.$emit("emergency-camera");
    },
    ...mapMutations({
      addLog: "log/addLog",
    }),
    ...mapActions({
      sendLog: "log/sendLog",
    }),
  },
};
</script>

<style lang="scss" scoped>
.pod-guests {
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: stretch;

  .guests {
    max-height: 65px;
    overflow-y: scroll;
    min-height: 40px;
    min-width: 50px;

    &::-webkit-scrollbar {
      background: rgba(216, 216, 216, 0.40879);
      border-radius: 1.5px;
      width: 3px;
    }

    &::-webkit-scrollbar-thumb {
      background: #d8d8d8;
    }
  }
}
main {
  margin-bottom: 5px;
}
.pod-guests header {
  font-size: 18px;
  font-weight: 600;
  text-align: center;
}

.pod-guests footer > * {
  margin: 0px 0 2px 0;
}

.pod-guests main.full {
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 68px;
  max-width: auto;
  display: grid;
  grid-template-columns: repeat(2, 2fr);
  grid-auto-rows: auto;
  flex-grow: 1;
}
.pod-guests main.list-guests {
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 170px;
  max-width: auto;
  display: grid;
  grid-template-columns: repeat(1, 3fr);
  grid-auto-rows: auto;
  flex-grow: 1;
}

.v-btn::v-deep .v-btn__content {
  font-weight: bold !important;
  font-size: 9px !important;
  line-height: 15px;
  padding: 0;
}

button {
  background-color: rgba(255, 255, 255, 0.3);
  margin-top: 20px;
  margin-bottom: 5px;
}
.btn {
  height: 25px !important;
}

@media screen and (min-width: 1420px) {
  .pod-guests {
    .guests {
      max-height: 85px;
    }
  }
  .pod-guests footer > * {
    margin: 0px 0 6px 0;
  }
  main {
    margin-bottom: 20px;
  }
  .flex-column {
    margin-top: 0px !important;
  }
  .pod-guests main.full {
    max-height: 73px;
    max-width: auto;
    display: grid;
    grid-template-columns: repeat(2, 2fr);
    grid-auto-rows: auto;
    flex-grow: 1;
  }
}
@media screen and (min-width: 1600px) {
  .pod-guests footer > * {
    margin: 0px 0 6px 0;
  }

  .v-btn::v-deep .v-btn__content {
    font-size: 13px !important;
  }
  .btn {
    height: 32px !important;
  }
}

@media screen and (min-width: 1920px) {
  .pod-guests {
    .guests {
      max-height: 105px;
    }
  }
  .pod-guests footer > * {
    margin: 0px 0 9px 0;
  }
  main {
    margin-bottom: 20px;
  }
  .flex-column {
    margin-top: 0px !important;
  }
  .pod-guests footer > * {
    margin: 0px 0 6px 0;
  }
  .v-btn::v-deep .v-btn__content {
    font-size: 15px !important;
  }
  .btn {
    height: 32px !important;
  }
   .pod-guests main.full {
    max-height: 95px;
    max-width: auto;
    display: grid;
    grid-template-columns: repeat(2, 2fr);
    grid-auto-rows: auto;
    flex-grow: 1;
  }
}

</style>

<template>
  <div class="item">
    <v-menu offset-x right top close-on-click :close-on-content-click="false" content-class="content">
      <template #activator="{ on }">
        <v-btn v-on="on" icon>
          <v-icon v-text="icon" />
        </v-btn>
      </template>

      <div class="pa-2">
        <slot>
          <v-card-title> Lorem ipsum dolor sit amet. </v-card-title>
          <v-card-text>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Perferendis
            nobis rem ullam consectetur nisi nulla labore tempore itaque vero
            at, magni veritatis placeat qui, officiis porro neque facilis
            consequatur molestiae.
          </v-card-text>
        </slot>
      </div>
    </v-menu>
  </div>
</template>

<script>
export default {
  props: {
    icon: { type: String, required: true },
  },
};
</script>

<style lang="scss" scoped>
.item {
  text-align: center;
  padding: 0.1rem;
}

.content {
  max-width: 600px;
  min-width: 250px;

  margin-left: 1.5rem;
  padding: 1.5rem;

  border-radius: 10px;
  border-bottom-left-radius: 0;

  background-color: rgba($color: #000, $alpha: 0.6);
  border: solid 3px rgba($color: #fff, $alpha: 0.7);
}
</style>

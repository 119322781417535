<template>
  <v-fade-transition mode="out-in">
    <div
      class="second-menu"
      :class="showTutorial ? 'menu-first' : 'main-menu-pod'"
    >
      <div ref="container" class="pod-menu">
        <template v-if="!toggleMenu">
          <div class="menu-template-pod-small">
            <v-icon
              v-show="!toggleMenu"
              @click="onTogglePanel()"
              class="control-icon"
              color="white"
              size="10"
              >fa fa-chevron-right
            </v-icon>
            <v-icon
              v-show="toggleMenu"
              @click="onTogglePanel()"
              class="control-icon"
              color="white"
              size="10"
              >fa fa-chevron-left
            </v-icon>

            <pod-menu-item
              class="menu-item"
              isIssetSubItems="true"
              ref="camera"
              icon="$vuetify.icons.cameraIcon"
            >
              <div class="camera" ref="camera">
                <h3 class="menu-title">
                  <v-icon size="22">$vuetify.icons.cameraIcon</v-icon>
                  Camera View
                </h3>
                <div class="menu-buttons">
                  <v-btn
                    class="btn"
                    :disabled="showTutorial"
                    block
                    small
                    outlined
                    @click="changeCamera('Personal')"
                    >PERSONAL VIEW</v-btn
                  >
                  <v-btn
                    class="btn"
                    :disabled="showTutorial"
                    block
                    small
                    outlined
                    @click="changeCamera('Pod')"
                    >POD VIEW</v-btn
                  >
                  <v-btn
                    class="btn"
                    :disabled="showTutorial"
                    block
                    small
                    outlined
                    @click="changeCamera('Broadcast')"
                    >BROADCAST VIEW</v-btn
                  >
                  <v-btn
                    class="btn"
                    :disabled="showTutorial"
                    block
                    small
                    outlined
                    @click="changeCamera('Free')"
                    >FREE FLY</v-btn
                  >
                </div>
              </div>
            </pod-menu-item>

            <pod-sub-menu
              menu-key="camera"
              :items="subMenu['camera']"
              @change-camera="changeCamera"
            />
          </div>
          <div class="menu-template-pod-small-middle">
            <pod-menu-item ref="people" icon="$vuetify.icons.peopleIcon">
              <h3 class="menu-title">
                <v-icon size="22">$vuetify.icons.peopleIcon</v-icon>
                Pod Pals
              </h3>
            </pod-menu-item>
            <pod-sub-menu
              class="pod-pals-guests-small"
              @mute="mute"
              @report="report"
              menu-key="people"
              :users="users"
            />
          </div>
          <div class="menu-template-pod-small pod-pals">
            <v-btn ref="changePod" icon @click="$emit('change-pod')">
              <v-icon size="24">$vuetify.icons.mapIcon</v-icon>
            </v-btn>
          </div>
          <div class="menu-template-pod-small">
            <v-btn ref="fullscreen" icon @click="handleFullscreen()">
              <v-icon size="18">$vuetify.icons.fullIcon</v-icon>
            </v-btn>
          </div>
        </template>

        <template v-if="toggleMenu">
          <div class="full-template">
            <div class="menu-template-pod">
              <v-icon
                v-show="toggleMenu"
                @click="onTogglePanel()"
                class="control-icon"
                color="white"
                size="10"
                >fa fa-chevron-left
              </v-icon>
              <div class="">
                <div class="camera" ref="camera">
                  <div>
                    <h3 class="menu-title">
                      <v-icon size="22" class="mr-1"
                        >$vuetify.icons.cameraIcon</v-icon
                      >
                      Camera View
                    </h3>
                  </div>
                  <div class="menu-buttons">
                    <v-btn
                      block
                      class="btn-up"
                      small
                      outlined
                      :disabled="showTutorial"
                      @click="
                        addLog(`${user} pressed 'PERSONAL VIEW'`);
                        sendLog();
                        changeCamera('Personal');
                      "
                      >PERSONAL VIEW</v-btn
                    >
                    <v-btn
                      block
                      small
                      class="btn-up"
                      outlined
                      :disabled="showTutorial"
                      @click="
                        addLog(`${user} pressed 'POD VIEW'`);
                        sendLog();
                        changeCamera('Pod');
                      "
                      >POD VIEW</v-btn
                    >
                    <v-btn
                      block
                      small
                      class="btn-up"
                      outlined
                      :disabled="showTutorial"
                      @click="
                        addLog(`${user} pressed 'BROADCAST VIEW'`);
                        sendLog();
                        changeCamera('Broadcast');
                      "
                      >BROADCAST VIEW</v-btn
                    >
                    <v-btn
                      block
                      small
                      class="btn-up"
                      outlined
                      :disabled="showTutorial"
                      @click="
                        addLog(`${user} pressed 'FREE FLY'`);
                        sendLog();
                        changeCamera('Free');
                      "
                      >FREE FLY</v-btn
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="menu-template-pod" ref="people">
              <h3 class="menu-title">
                <v-icon size="22" class="mr-1"
                  >$vuetify.icons.peopleIcon</v-icon
                >
                Pod Pals
              </h3>
              <pod-guests
                class="pod-pals-guests"
                :isExpanded="toggleMenu"
                :users="users"
                :emergency-available="emergencyAvailable"
                :is-admin="isAdmin"
                @mute="mute"
                @report="report"
                @bug-report="bugReport"
                @emergency-camera="emergencyCamera"
              />
            </div>
            <div class="menu-template-pod menu-template-pod-down">
              <v-btn
                ref="changePod"
                text
                small
                :disabled="!started || showTutorial"
                @click="
                  addLog(`${user} pressed 'MAP VIEW'`);
                  sendLog();
                  $emit('change-pod');
                "
              >
                <v-icon size="24" class="mr-2">$vuetify.icons.mapIcon</v-icon>
                <span class="menu-title">MAP VIEW</span>
              </v-btn>
            </div>
            <div class="menu-template-pod-down">
              <v-btn
                small
                ref="fullscreen"
                text
                @click="
                  handleFullscreen();
                  addLog(`${user} pressed 'FULL SCREEN'`);
                  sendLog();
                "
              >
                <v-icon class="mr-2" size="18">$vuetify.icons.fullIcon</v-icon>
                <span class="menu-title">FULL SCREEN</span>
              </v-btn>
            </div>
          </div>
        </template>
      </div>
      <div
        v-show="showTutorial"
        :class="!toggleMenu ? 'hints-separate-btn-small' : 'hints-separate-btn'"
      >
        <div class="up-block-red-btn">
          <div class="tutorial-hint-red-btn">CHANGE VIEW</div>
        </div>
        <div class="middle-block-red-btn">
          <div class="tutorial-hint-red-btn">POD PALS</div>
        </div>
        <div class="down-block-red-btn">
          <div class="tutorial-hint-red-btn btn-change-pod">CHANGE POD</div>
          <div class="tutorial-hint-red-btn">FULLSCREEN</div>
        </div>
      </div>
    </div>
  </v-fade-transition>
</template>

<script>
import PodGuests from "./PodGuests.vue";
import PodMenuItem from "./PodMenuItem.vue";
import PodSubMenu from "./PodSubMenu.vue";
import Icon1 from "@/assets/menu/icon-1.svg";
import People from "@/assets/menu/people.svg";
import Share from "@/assets/menu/share.svg";
import Signal from "@/assets/menu/signal.svg";
import { mapState, mapMutations, mapActions } from "vuex";

// let timer;
// let component;

// function hideMenuOnMove() {
//   if (timer) {
//     clearTimeout(timer);
//   }

//   component.visible = true;

//   timer = setTimeout(() => { component.visible = false; }, 3000);
// }

export default {
  components: {
    PodMenuItem,
    PodGuests,
    PodSubMenu,
  },
  props: {
    fullscreen: { type: Boolean, default: false },
    showTutorial: { type: Boolean, default: false },
    users: { type: Array, default: () => [] },
    emergencyAvailable: { type: Boolean, default: false },
    isAdmin: { type: Boolean, default: false },
    started: { type: Boolean, default: false },
    isAddMenu: { type: Boolean, default: false },
  },
  data: () => ({
    toggleMenu: true,
    visible: true,
    subMenu: {
      camera: [
        { title: "people", icon: People, value: "Personal" },
        { title: "icon1", icon: Icon1, value: "Pod" },
        { title: "signal", icon: Signal, value: "Broadcast" },
        { title: "share", icon: Share, value: "Free" },
      ],
    },
    scaleFactor: 1,
  }),
  computed: {
    ...mapState({
      user: (state) => state.auth.user.username,
    }),
  },
  mounted() {
    //  this.scaleMenu()
  },
  methods: {
    scaleMenu() {
      const width =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;
      const height =
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight;

      if (width < 1100) {
        this.scaleFactor = 0.8;
      }
      if (height < 720) {
        this.scaleFactor = 0.6;
      }
      this.$refs.container.style.zoom = `${this.scaleFactor}`;
    },

    onTogglePanel() {
      this.toggleMenu = !this.toggleMenu;
      this.toggleTutorial();
    },
    mute(users) {
      this.$emit("mute", users);
    },
    report(user) {
      this.$emit("report", user);
    },
    bugReport() {
      this.$emit("bug-report");
    },
    emergencyCamera() {
      this.$emit("emergency-camera");
    },
    changeCrowdVolume(value) {
      this.$emit("change-crowd-volume", value);
    },
    changeMicroVolume(value) {
      this.$emit("change-micro-volume", value);
    },
    changeVolume(value) {
      this.$emit("change-volume", value);
    },
    changeCamera(value) {
      this.$emit("change-camera", value);
    },
    handleFullscreen() {
      this.$emit("fullscreen-toggle");
      document.querySelector(".pod-page").requestFullscreen();
    },
    ...mapMutations({
      addLog: "log/addLog",
    }),
    ...mapActions({
      sendLog: "log/sendLog",
    }),
  },
  hideMenuHandler: null,
  watch: {
    async fullscreen(value) {
      // if (timer) {
      //   clearTimeout(timer);
      // }

      // timer = null;
      // component = this;

      // const frame = document.querySelector("#pod-stream");
      // const doc = frame.contentWindow || frame.contentDocument;

      // frame.postMessage({ type: 'FULLSCREEN', value }, '*');

      // doc.addEventListener('mousemove', hideMenuOnMove);
      // doc.removeEventListener('mousemove', hideMenuOnMove);

      if (!value) {
        this.visible = true;

        try {
          console.log(document.fullscreenElement);
          if (document.fullscreenElement) {
            await document.exitFullscreen();
          }
        } catch (e) {
          console.warn(e);
        }
      }
    },
  },
};
</script>

<style lang="scss">
.tutorial-hint-invisible {
  opacity: 0;
}

.tutorial-hint-fade-in {
  opacity: 1;
}
</style>

<style lang="scss" scoped>
.pod-menu {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-around;
  align-items: stretch;
  z-index: 110;
  position: relative;
  background-color: rgba(0, 0, 0, 0.5);
  border: 2px solid white;
  border-radius: 12px;
  .full-template {
    max-width: 190px;
    min-width: 190px;
  }
}

.menu-template-pod {
  position: relative;
  padding: 7px 9px;
  border-bottom: 3px solid #ccc;
  z-index: 100;

  &:last-child {
    border: none;
  }
  &:after,
  &:before {
    content: none;
  }
}
.menu-template-pod-small {
  position: relative;
  padding: 2px 20px;
  border-bottom: 3px solid #ccc;
  z-index: 100;
  display: flex;
  flex-direction: column;
  align-items: center;

  &:last-child {
    border: none;
  }
  &:after,
  &:before {
    content: none;
  }

}
.menu-template-pod-small-middle {
   border-bottom: 3px solid #ccc;
  }  
.menu-template-pod-down {
  position: relative;
  padding: 2px 0px;
  z-index: 100;
}
.pod-menu--hint {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-around;
  z-index: 110;
  position: relative;
  background-color: rgba(0, 0, 0, 0.5);
  border: 2px solid white;
  border-radius: 12px;
  .full-template {
    max-width: 190px;
    min-width: 190px;
  }
}
.second-menu {
  display: flex;
}
.hints-separate-btn {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 110;
  margin-right: 7px;
}
.hints-separate-btn-small {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 110;
  margin-right: 118px;
}
.pod-pals-guests {
  min-height: 200px;
}

.tutorial-hint-red-btn {
  width: 50%;
  text-align: center;
  color: rgba(255, 60, 17, 1);
  font-size: 15px;
  height: 33px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Oswald;
  font-style: normal;
  font-weight: 500;
  letter-spacing: -1px;
  border: solid 1px rgba(255, 255, 255, 1);
  border-radius: 7px;
  background-color: rgba(0, 0, 0, 0.543271);
  transition: all 0.7s ease-out;
  margin-right: 100px;
  margin-left: 20px;
}
.btn-change-pod {
  margin-bottom: 5px;
}
.control-icon {
  position: absolute;
  right: 5px;
  text-align: right;
  display: inline-block;
}

.pod-menu .v-expansion-panel-header {
  flex: unset;
  // padding: 0.5rem;
}

.pod-menu >>> .v-slider__thumb {
  width: 20px !important;
  height: 20px !important;
}

.v-icon.v-icon::after {
  background-color: inherit;
}

.pod-menu >>> .v-slider__thumb.primary {
  color: #ff3c11 !important;
  background-color: #ff3c11 !important;
}

.pod-menu > .v-divider {
  // margin: 25px 0;
}

h3.menu-title {
  font-weight: bold;
  font-size: 11px;
  line-height: 23px;
  text-align: center;
  text-transform: uppercase;
}

span.menu-title {
  font-weight: bold;
  font-size: 9px;
  text-align: center;
  text-transform: uppercase;
}

.slider-row {
  display: flex;

  span {
    display: inline-block;
    width: 75px;
  }
  padding-top: 10px;
}

.up-down-buttons {
  .fa-angle-left {
    margin-right: 5px;
  }
}

.menu-buttons {
  .v-btn::v-deep span {
    font-weight: bold !important;
    font-size: 9px !important;
  }
}

.menu-buttons {
  button {
    background-color: rgba(255, 255, 255, 0.3);
    margin-top: 4px;
  }
}

button {
  text-align: center;
  width: 100% !important;
}

.menu-hint {
  color: #fff;
  font-size: 18px;
  font-weight: 700;
  font-family: "Oswald";
}

.btn-up {
  height: 25px !important;
}

@media screen and (min-width: 1420px) {
  .menu-template-pod {
    padding: 10px 10px;
  }
  .menu-template-pod-small {
    padding: 5px 30px;
  }
  .menu-template-pod-down {
    padding: 7px 0px;
  }
  .pod-menu--hint {
    .full-template {
      max-width: 230px;
      min-width: 230px;
    }
  }
  .pod-pals-guests {
    min-height: 150px;
  }
  .hints-separate-btn {
    margin-right: 70px;
  }
  .hints-separate-btn-small {
    margin-right: 160px;
  }
  .tutorial-hint-red-btn {
    width: 60%;
    font-size: 17px;
    height: 44px;
  }
  h3.menu-title {
    font-size: 13px;
  }

  span.menu-title {
    font-size: 12px;
  }
  .menu-buttons {
    .v-btn::v-deep span {
      font-size: 12px !important;
    }
  }
  .menu-buttons {
    button {
      margin-top: 4px;
    }
  }
  .btn-up {
    height: 30px !important;
  }
  
}
@media screen and (min-width: 1600px) {
  .pod-menu {
    .full-template {
      max-width: 230px;
      min-width: 230px;
    }
  }
  .menu-template-pod {
    padding: 13px 10px;
  }
 
 .menu-template-pod-small {
    padding: 10px 35px;
  }
  .pod-menu--hint {
    .full-template {
      max-width: 230px;
      min-width: 230px;
    }
  }
  .pod-pals-guests {
    min-height: 250px;
  }
  .hints-separate-btn {
    margin-right: 90px;
  }
  .hints-separate-btn-small {
    margin-right: 210px;
  }
  .tutorial-hint-red-btn {
    width: 60%;
    font-size: 19px;
    height: 45px;
  }
  .btn-change-pod {
    margin-bottom: 10px;
  }
  h3.menu-title {
    font-size: 14px;
  }

  span.menu-title {
    font-weight: bold;
    font-size: 14px;
  }
  .menu-buttons {
    .v-btn::v-deep span {
      font-size: 12px !important;
    }
  }
  .menu-buttons {
    button {
      margin-top: 6px;
    }
  }

  .btn-up {
    height: 30px !important;
  }
}

@media screen and (min-width: 1920px) {
  .pod-menu {
    .full-template {
      max-width: 270px;
      min-width: 270px;
    }
  }
  .menu-template-pod {
    padding: 25px 10px;
  }
   .menu-template-pod-small {
    padding: 15px 45px;
  }
  .menu-template-pod-down {
    padding: 10px 10px;
  }
  .menu-template-pod-small-middle {
  height: 280px;
  } 
  .pod-menu--hint {
    .full-template {
      max-width: 280px;
      min-width: 280px;
    }
  }

  .hints-separate-btn {
    margin-right: 140px;
  }
  .hints-separate-btn-small {
    margin-right: 275px;
  }
  .tutorial-hint-red-btn {
    width: 70%;
    font-size: 19px;
    height: 55px;
  }
  .btn-change-pod {
    margin-bottom: 15px;
  }
  h3.menu-title {
    font-size: 17px;
  }

  span.menu-title {
    font-size: 17px;
  }
  .menu-buttons {
    .v-btn::v-deep span {
      font-size: 15px !important;
    }
  }
  .menu-buttons {
    button {
      margin-top: 6px;
    }
  }

  .btn-up {
    height: 32px !important;
  }

  .tutorial-hint-red-btn {
    width: 70%;
    font-size: 22px;
    height: 53px;
    margin-right: 100px;
  }
}
</style>

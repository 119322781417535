<template>
  <div class="sub-items">
    <template v-if="menuKey === 'camera'">
      <div v-for="(item, i) in items" :key="i" class="sub-item">
        <v-btn x-small outlined @click="$emit('change-camera', item.value)">
          <img width="15px" :src="item.icon" :alt="item.title" />
        </v-btn>
      </div>
    </template>

    <template v-if="menuKey === 'people'">
      <pod-guests
        class="pod-guests"
        :users="users"
        @mute="mute"
        @report="report"
      />
    </template>
  </div>
</template>

<script>
import PodGuests from "./PodGuests";

export default {
  methods: {
    mute(users) {
      this.$emit("mute", users);
    },
    report(user) {
      this.$emit("report", user);
    },
  },
  components: { PodGuests },
  props: {
    users: { type: Array, default: () => [] },
    menuKey: { type: String, required: true },
    items: { type: Array, default: null },
  },
};
</script>

<style lang="scss" scoped>
.sub-items {
  .rules {
    min-height: 60px;
    button {
      background: rgba(255, 255, 255, 0.16);
    }
  }

  span {
    font-size: 10px !important;
    color: #ffffff;
  }

  .people-circle {
    button {
      background: #cccccc;
      width: 30px;
      border: 1px solid #ccc;
      height: 30px !important;
      border-radius: 50%;
    }
  }

  span.title {
    color: #cccccc;
    font-size: 10px !important;
  }

  .sub-item {
    button {
      height: 22px;
    }

    margin-bottom: 2px;
  }
}

@media screen and (min-width: 1600px) {
  .sub-items {
    text-align: center;

    .people-circle {
      button {
        background: #cccccc;
        width: 35px;
        border: 1px solid #ccc;
        height: 35px !important;
        border-radius: 50%;
      }
    }

    span.title {
      color: #cccccc;
      font-size: 10px !important;
    }

    .sub-item {
      button {
        height: 28px;
      }

      margin-bottom: 2px;
    }
  }
}
@media screen and (min-width: 1920px) {
  .sub-items {

  .people-circle {
    button {
      background: #cccccc;
      width: 35px;
      border: 1px solid #ccc;
      height: 35px !important;
      border-radius: 50%;
    }
  }

  span.title {
    color: #cccccc;
    font-size: 10px !important;
  }

  .sub-item {
    button {
      height: 36px;
    }

    margin-bottom: 6px;
  }
}
.pod-guests {

}
}
</style>
